import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "../../container"
import { Link } from "gatsby"
import { register } from 'swiper/element/bundle';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


import { RealisationsSection, RealisationElement } from "./home-realization.style"
import SectionTitle from "../../section-title"



export default function Realizations() {
    const [isReady, setIsReady] = useState(true)

    register();

    const data = useStaticQuery(graphql`
    query ProjectsQuery {
        allWpProject {
          nodes {
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      quality: 100
                      avifOptions: {lossless: true, quality: 100}
                    )
                  }
                }
              }
            }
            title
            uri
            date
          }
        }
      }
  `)


    useEffect(() => {
        setIsReady(true)
    }, [])

  const elements = data.allWpProject.nodes

  const pagination = {
    clickable: true,
    dynamicBullets: true,
  };


  return (
    <RealisationsSection id="RealizacjeGrupaTaka">
      {isReady && (
        <>
            <Container>
              <SectionTitle
                text="napędziliśmy_"
                textBold="też"
                textColor="#000"
              />
            </Container>
            <div className="realisations realisations--desktop">
            {elements.map(item => (
            <RealisationElement className="singleRealisation" key={item.title}>
            <Link className="singleRealisation__link" to={item.uri}>
                <figure className="singleRealisation__image">
                  <GatsbyImage
                    image={getImage(item.featuredImage.node.localFile)}
                    alt={item.title}
                    title={item.title}
                  />
                </figure>
                <div className="singleRealisation__content">
                    <h3
                      className="singleRealisation__title"
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    ></h3>
                </div>
              </Link>
            </RealisationElement>
          ))}
            </div>
            <div className="realisations realisations--mobile">
              <Swiper loop="true" pagination={pagination} modules={[Pagination]} className="mySwiper" >
                {elements.map(item => (
                  <SwiperSlide>
                    <RealisationElement className="singleRealisation" key={item.title}>
                      <Link className="singleRealisation__link" to={item.uri}>
                          <figure className="singleRealisation__image">
                            <GatsbyImage
                              image={getImage(item.featuredImage.node.localFile)}
                              alt={item.title}
                              title={item.title}
                            />
                          </figure>
                          <div className="singleRealisation__content">
                              <h3
                                className="singleRealisation__title"
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              ></h3>
                          </div>
                        </Link>
                    </RealisationElement>
                  </SwiperSlide>
                  ))}
              </Swiper>
            </div>
        </>
      )}
    </RealisationsSection>
  )
}
