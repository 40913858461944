import React from "react"
import { AccordionItem } from "react-headless-accordion";
import { StyledAccordionBody, StyledAccordionHeader, StyledAccordion } from "./accordion.style"
import LinkButton from "../../link-button"


export default function CustomizedAccordion(props ) {
    const elements = props.data;
    let TitleSplited = '';

    console.log(elements)


    return (
        <StyledAccordion className="accoradion" transition={{duration: "350ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)"}}>
            {elements.map((item, index) => (
                <AccordionItem isActive={index === 0 ? true : false} key={index}>
                    {({open}) => (
                        <>
                            <div className={`accordion__element ${open ? 'accordion-active' : ''}`}>
                                <StyledAccordionHeader className={`${open ? 'accordion-active' : ''}`}>
                                    <h3 className={`accordion__title ${open ? 'accordion-active' : ''}`}>
                                        <span className="fistLine">{item.title.split('_')[0]}</span>
                                        <span>{item.title.split('_')[1]}</span>

                                    </h3>
                                    <div className="arrow">{`${open ? 'mniej -' : 'więcej +'}`}</div>
                                </StyledAccordionHeader>

                                <StyledAccordionBody className={`accordion__item ${open ? 'accordion-active' : ''}`}>
                                    <div className="accordion__wrapper">

                                        <div
                                        className="accordion__text"
                                        dangerouslySetInnerHTML={{ __html: item.content }}
                                        />

                                        <div className="accordion__links">
                                        {item.ACF_offer.links?.map((linkItem, index) => (
                                            <LinkButton
                                            key={index}
                                            url={linkItem.link.url}
                                            text={linkItem.link.title}
                                            target={linkItem.link.target}
                                            />
                                        ))}
                                        </div>
                                    </div>
                                </StyledAccordionBody>
                            </div>
                        </>
                    )}
                </AccordionItem>
            ))}
        </StyledAccordion>
    );
};
