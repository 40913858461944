import React, { useEffect, useState, useRef  } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "../../container"
import { Link } from "gatsby"


import { StyledSectionCS, CaseStudyElement } from "./case-studies.style"
import SectionTitle from "../../section-title"



export default function CaseStudies() {
    const [isReady, setIsReady] = useState(true)
    const heightRef = useRef(null);

    const data = useStaticQuery(graphql`
    query CSMyQuery {
      allWpCaseStudie(filter: {title: {ne: "Kirsch"}}, sort: {date: DESC}) {
        nodes {
          ACF_case_study {
            description
            slogan
          }
          title
          content
          uri
          featuredImage {
            node {
              title
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    avifOptions: { lossless: true, quality: 100 }
                  )
                }
              }
            }
          }
        }
      }
    }
  `)


    useEffect(() => {

      setIsReady(true)
    }, [])

  const elements = data.allWpCaseStudie.nodes


  return (
    <StyledSectionCS id="CaseStudy">
      {isReady && (
        <Container>
            <SectionTitle
              text="jak_"
              textBold="napędzamy"
              textColor="#000"
            />
            <div ref={heightRef} className="caseStudy">
            {elements.map((item, i) => (
            <CaseStudyElement textHeight={heightRef.current?.children[i].getElementsByClassName('singleCase__text')[0].clientHeight || 0} className="singleCase" key={item.title}>
              <Link className="singleCase__link" to={item.uri}>
              <div className="singleCase__wrapper">
                <figure className="singleCase__image">
                  <GatsbyImage
                    image={getImage(item.featuredImage.node.localFile)}
                    alt={item.title}
                    title={item.title}
                  />
                </figure>
                <div className="singleCase__content">
                  <div className="singleCase__top">
                    <h3
                      className="singleCase__title"
                      dangerouslySetInnerHTML={{
                        __html: item.ACF_case_study.slogan,
                      }}
                    ></h3>
                    <div
                    className="singleCase__desc"
                    dangerouslySetInnerHTML={{
                        __html: item.ACF_case_study.description,
                      }}
                    />
                  </div>
                  <div
                    className="singleCase__text"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </div>
              </div>
              </Link>
            </CaseStudyElement>
          ))}
            </div>
        </Container>
      )}
    </StyledSectionCS>
  )
}
