import React, { useEffect, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import SectionTitle from "../../section-title"
import { AboutSection, AboutText } from "./home-about.style"
import Container from "../../container"
import ButtonPrimary from "../../atoms/ButtonPrimary"


export default function HomeAbout(data) {
    const [isReady, setIsReady] = useState(true)

    const AboutData = data.data;

    useEffect(() => {
        setIsReady(true)
    }, [])


  return (
    <AboutSection>
      {isReady && (
        <>
          <Container>
            <SectionTitle
              text="napędzamy_"
              textBold="zespołowo"
              textColor="#000"
            />
            <div>
                <AboutText>
                  <div dangerouslySetInnerHTML={{
                    __html: AboutData.about,
                  }} />
                  <ButtonPrimary
                    text="więcej o firmie"
                    anchor="/o-firmie"
                    hoverColor='#000'
                  />
                </AboutText>
                <GatsbyImage
                    image={getImage(AboutData.groupphoto.localFile)}
                    alt={AboutData.groupphoto.alt}
                    title={AboutData.groupphoto.title}
                />
            </div>
          </Container>
        </>
      )}
    </AboutSection>
  )
}
