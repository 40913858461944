import React, { useEffect, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import SectionTitle from "../../section-title"
import { ClientsList, SingleLogo } from "./home-clients.style"
import Container from "../../container"


export default function HomeClients(data) {
    const [isReady, setIsReady] = useState(true)

    const Homedata = data;

    const ClientsItems = Homedata.data.clients.map(
        (item, index) => {
          return (
            <SingleLogo key={index} className="client__single">
                <GatsbyImage
                image={getImage(item.logo.localFile)}
                alt={item.logo.title}
                title={item.logo.title}
                />
            </SingleLogo>
          )
        }
      )


    useEffect(() => {
        setIsReady(true)
    }, [])


  return (
    <section>
      {isReady && (
        <Container>
            <SectionTitle
              text="napędzaliśmy_"
              textBold="dla"
              textColor="#000"
            />
            <ClientsList className="client__wrapper">{ClientsItems}</ClientsList>
        </Container>
      )}
    </section>
  )
}
