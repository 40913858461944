import SwiperCore, { Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import React from "react"

const WordSlider = props => {
  SwiperCore.use([Pagination, Autoplay])

  let SliderContent = props.content
  let params = {
    slidesPerView: 1,
    spaceBetween: 30,
    direction: "vertical",
    autoplay: {
      delay: 1500,
    },
  }

  return (
    <Swiper {...params}>
      {SliderContent.map((item, index) => (
        <SwiperSlide key={index}><div>{item.item}</div></SwiperSlide>
      ))}
    </Swiper>
  )
}

export default WordSlider
