import styled from "styled-components"

export const SectionOffer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 3rem 0;

  @media ${props => props.theme.sizes.laptop} {
        padding: 6rem 0;
        margin: 3rem 0 3rem;
  }
`
export const OfferElement = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`