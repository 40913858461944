import styled from "styled-components"

export const ClientsList = styled.section`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    padding: 3rem 0;

    @media ${props => props.theme.sizes.tablet} {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1.5rem;
      grid-row-gap: 3rem;
    }

    @media ${props => props.theme.sizes.laptop} {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 1.5rem;
      grid-row-gap: 3rem;
    }
`
export const SingleLogo = styled.figure`
    filter: grayscale(1);
    opacity: 0.6;
    transition: all 0.5s;
    margin: 0;

    &:hover {
      filter: grayscale(0);
      opacity: 1;
      transition: all 0.5s;
    }
`
