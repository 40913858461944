import styled from "styled-components"
import {Accordion, AccordionBody, AccordionHeader} from "react-headless-accordion";

export const StyledAccordionBody = styled(AccordionBody)`
    .accordion__wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media ${props => props.theme.sizes.laptop} {
          flex-direction: row;
        }
    }
    .accordion__text {
        width: 100%auto;
        line-height: 1.6rem;
        font-size: .9rem;
        font-family: ${props => props.theme.fonts.secondary};
        font-weight: 200;

        @media ${props => props.theme.sizes.laptop} {
          flex-direction: row;
          font-size: 1rem;
          width: clamp(45ch, 50%, 75ch);
        }
    }
    .accordion__links {
        display: flex;
        flex-direction: column;
        grid-gap: .6rem;
        text-align: left;
    }
`
export const StyledAccordionHeader = styled(AccordionHeader)`
    line-height: 0;
    text-transform: lowercase;
    background: none;
    border: none;
    cursor: pointer;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow {
        padding: 1rem 0;
        font-weight: 400;
        font-family: ${props => props.theme.fonts.primary};
        font-size: 16px;
        width: 100%;
        text-align: right;
        /* transition: transform .35s ease-in-out; */
    }

    &.accordion-active {
        padding: 1rem 0 0;
    }

    .accordion__title {
        font-size: 1.9rem;
        font-weight: 200;
        font-family: ${props => props.theme.fonts.primary};
        transition: color .15s ease-in-out;
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        margin: 1rem 0;

        span {
            height: auto;
            line-height: 1;
        }

        &.accordion-active {
            /* font-weight: 500; */
            color: ${props => props.theme.colors.primary};
            transition: color .15s ease-in-out;
        }

        @media ${props => props.theme.sizes.laptop} {
          flex-direction: row;
          font-size: 2.5rem;
          margin: 2.2rem 0;

          .fistLine {
            margin-right: .5rem;
          }
        }
    }


`
export const StyledAccordion = styled(Accordion)`
    .accordion__element {
        /* border-bottom: 1px solid black; */
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background: #d5d5d5ee;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background: ${props => props.theme.colors.primary};
            transform: translateX(-100%);
            transition: transform .95s ease-in-out;
        }

        &.accordion-active {
            padding-bottom: 1rem;
            border-color: ${props => props.theme.colors.primary};

            &::after {
                transform: translateX(0%);
                transition: transform 0.95s ease-in-out;
            }
        }
    }

`
