import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  font-size: clamp(.7rem, .8rem, 1rem);
  font-family: ${props => props.theme.fonts.primary};
  text-transform: lowercase;
  font-weight: 500;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  text-align: left;

  @media ${props => props.theme.sizes.laptop} {
    text-align: right;
    font-size: clamp(.7rem, 1rem, 1rem);
}
`

export default function LinkButton(props) {
    return (
        <StyledLink to={props.url} target={props.target}>
            {props.text}
            <span> ></span>
        </StyledLink>
    )
}