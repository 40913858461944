import React from "react"
import styled from "styled-components"

const StyledSectionTitle = styled.h2`
  font-size: clamp(2rem, 2.5rem, 4rem);
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.color || "palevioletred"};
  text-transform: lowercase;
  font-weight: 400;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 800;
  }

  @media ${props => props.theme.sizes.tablet} {
    font-size: clamp(2rem, 2.8rem, 2.8rem);
  }

  @media ${props => props.theme.sizes.laptop} {
    font-size: clamp(2rem, 3rem, 3rem);
    flex-direction: row;
  }
  @media ${props => props.theme.sizes.laptopL} {
    font-size: clamp(2rem, 3.6rem, 3.6rem);
    flex-direction: row;
  }
  @media ${props => props.theme.sizes.desktop} {
    font-size: clamp(2rem, 4.4rem, 4.4rem);
  }
`



export default function SectionTitle(props) {
    return (
        <StyledSectionTitle color={props.textColor} to="/blog">
            {props.text}
            <span>{props.textBold}</span>
        </StyledSectionTitle>
    )
}