import React, { useEffect, useState } from "react"

import { BannerTitile, SectionBanner, BannerText } from "./home-banner.style"
import AnimatedSquare from "../../animated-square"
import Container from "../../container"
import WordSlider from "./word-slider"
import SquareSVG from "../../../images/svg/logo2.svg"


export default function HomeBanner({ data }) {
    const [isReady, setIsReady] = useState(false)



  useEffect(() => {
      setIsReady(true)
  }, [])

  return (
    <SectionBanner id="bannerSecction">

          <Container>
            <div className="banner__wrapper">
              <div>
                <BannerTitile id="bannerContent">
                    <div>napędzamy_</div>
                    <WordSlider content={data.titleslider} />
                </BannerTitile>
                <BannerText
                    dangerouslySetInnerHTML={{ __html: data.tekst }}
                ></BannerText>
              </div>
              <div className="banner__square">
                <SquareSVG id="animatedLogo" class="animated-logo" />

              </div>
            </div>
          </Container>
          <AnimatedSquare
              width="70rem"
              height="70rem"
              top="-35rem"
              right="-20rem"
              phoneTop="-35rem"
          />
    </SectionBanner>
  )
}
