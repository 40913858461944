import styled from "styled-components"

export const SectionBanner = styled.section`
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-bottom: 15vh;
  overflow: hidden;
  height: 70vh;

  @media ${props => props.theme.sizes.mobileL} {
    height: 60vh;
    padding-bottom: 10vh;
  }
  @media ${props => props.theme.sizes.laptop} {
    height: 70vh;
    padding-bottom: 10vh;
  }
  @media ${props => props.theme.sizes.laptopL} {
    height: 80vh;
    align-items: center;
  }



  #shape2,
  #shape3,
  #shape4,
  #shape5,
  #shape6,
  #shape7,
  #shape8,
  #shape9,
  #shape10,
  #shape11,
  #shape12,
  #shape13,
  #shape14,
  #shape15,
  #shape16 {
    visibility: hidden;
  }

  .banner__wrapper {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 2rem;


    @media ${props => props.theme.sizes.laptop} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @media ${props => props.theme.sizes.laptopL} {
      padding-bottom: 10vh;
    }
  }
  .banner__square {
    width: 100%;
    height: auto;
    max-width: 500px;
    padding-bottom: 5%;
    display: none;

    @media ${props => props.theme.sizes.mobileM} {
      display: block;
    }
  }

  .bannerSquare, .animated-logo {
    max-width: 250px;
    display: none;

    @media ${props => props.theme.sizes.mobileM} {
      display: block;
      max-width: 220px;
      margin: 0;
    }
    @media ${props => props.theme.sizes.laptop} {
      max-width: 300px;
      margin: 0 auto;
    }
    @media ${props => props.theme.sizes.laptop17} {
      max-width: 400px;
    }

    @media ${props => props.theme.sizes.desktop} {
      max-width: 500px;
      width: 450px;
      height: 450px;
      margin: 0 7% 7% 0;
    }
  }
  .button {
    text-align: left;
    max-width: 45%;
    padding-right: 1rem;

    @media ${props => props.theme.sizes.tablet} {
      text-align: right;
    }
  }
  ul {
    position: absolute;
    right: 3%;
  }
  li {
    margin-bottom: 1.5rem;

    a span {
      display: none;
    }
    a .icon {
      display: contents;
      left: 0;
    }
  }
`

export const BannerTitile = styled.h1`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 0;
  font-weight: 400;
  text-transform: lowercase;
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.sizes.tablet} {
    font-size: 4.5rem;
    margin-bottom: 2rem;
  }
  @media ${props => props.theme.sizes.laptop} {
    font-size: 3.2rem;
    margin-bottom: 1rem;
  }
  @media ${props => props.theme.sizes.laptopL} {
    font-size: 4.5rem;
    margin-bottom: 2rem;
  }
  @media ${props => props.theme.sizes.laptop17} {
    font-size: 5.5rem;
    margin-bottom: 2rem;
  }
  .swiper-container {
    margin-left: 0;

    @media ${props => props.theme.sizes.tablet} {
      margin-left: auto;
    }
  }
  .swiper-slide {
    font-weight: 800;
  }
  .swiper {
    height: 70px;
    overflow: hidden;
    margin-top: 20px;
    margin-left: 0;

    @media ${props => props.theme.sizes.tablet} {
      margin-top: 30px;
      height: 110px;
    }
  }
`

export const BannerText = styled.div`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
  color: ${props => props.theme.colors.text};

  @media ${props => props.theme.sizes.tablet} {
    font-size: 1.2rem;
  }
  @media ${props => props.theme.sizes.laptop} {
    font-size: 1rem;
  }
  @media ${props => props.theme.sizes.laptop17} {
    font-size: 1.4rem;
  }
`
