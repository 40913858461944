import styled from "styled-components"

export const RealisationsSection = styled.section`
    padding: 3rem 0;


  .realisations {
    display: none;
    flex-direction: column;
    grid-gap: 40px;
    padding: 40px 0;

    @media ${props => props.theme.sizes.laptop} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        padding: 70px 0 200px;
    }
    @media ${props => props.theme.sizes.laptopL} {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 40px;
        padding: 70px 0 200px;
    }

    &--mobile {
        display: block;

        @media ${props => props.theme.sizes.laptop} {
            display: none;
        }
    }

  }
`

export const RealisationElement = styled.div`
    position: relative;
    height: 100%;
    min-height: 470px;

    @media ${props => props.theme.sizes.laptop} {
        &:nth-of-type(2n + 2) {
            margin-top: 80px;
        }
    }

    .singleRealisation {
        &__content {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

        }
        &__image {
            height: 100%;
            position: relative;
            position: absolute;


            .gatsby-image-wrapper {
                height: 100%;
            }

            &::after {
                content: '';
                background: #000;
                width: 100%;
                height: 100%;
                opacity: .4;
                position: absolute;
                top: 0;
                left: 0;
                transition: opacity 0.2s;
            }
        }
        &__title {
            font-family: ${props => props.theme.fonts.primary};
            color: #fff;
            font-size: 2.5rem;
            line-height: 56px;
            text-align: center;
            padding: 1rem;
        }
    }
`
