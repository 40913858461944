import styled from "styled-components"

export const StyledSectionCS = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 3rem 0 3rem;

  @media ${props => props.theme.sizes.tablet} {
    margin: 0rem 0;
  }

  @media ${props => props.theme.sizes.laptop} {
    margin: 0 0 6rem;
  }

  .caseStudy {
    display: flex;
    flex-direction: column;
    grid-gap: 50px;

    @media ${props => props.theme.sizes.laptop} {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media ${props => props.theme.sizes.laptopL} {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-top: 1rem;
    }
  }
`

export const CaseStudyElement = styled.div`
  background: black;
  height: 100%;
  cursor: pointer;

  &:hover {
    .singleCase__image {
      &::after {
            opacity: .7;
            transition: opacity 0.2s;
        }
    }
    .singleCase__content {
      bottom: 0;
      transform: none;
      transition: 300ms bottom linear;
    }
    .singleCase__text, .singleCase__desc, .singleCase__title {
      transform: none;
      opacity: 1;
      transition: 500ms opacity linear, 300ms transform ease-in-out;
    }
  }

  @media ${props => props.theme.sizes.laptopL} {
    &:nth-of-type(3n + 2) {
      margin-top: 50px;
    }
  }

  .singleCase {
    &__wrapper {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
    }

    &__image {
        margin: 0;
        position: relative;
        height: 100%;

        .gatsby-image-wrapper {
          height: 100%;
        }

        &::after {
            content: '';
            background: #000;
            width: 100%;
            height: 100%;
            opacity: .4;
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity 0.2s;
        }
    }
    &__content {
        font-family: ${props => props.theme.fonts.primary};
        position: absolute;
        left: 0;
        color: #fff;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        bottom: 0;
        transition: 300ms bottom linear;

        @media ${props => props.theme.sizes.laptop} {
          bottom: -100px;
          padding: 40px;
        }
        @media ${props => props.theme.sizes.laptopL} {
          bottom: -150px;
          padding: 40px;
        }


    }
    &__title {
        font-size: 2.4rem;
        line-height: 56px;
        margin: 0;

        @media ${props => props.theme.sizes.laptop} {
          font-size: 2.5rem;
        }

        p {
          margin: 1rem 0;
        }
    }
    &__text {
      display: none;

      @media ${props => props.theme.sizes.laptop} {
        display: block;
        opacity: 0;
      }
    }
    &__desc, &__text {
        font-family: ${props => props.theme.fonts.secondary};
        font-weight: 300;
        font-size: 1rem;
        transform: translateY(2ex);
        transition: 1000ms opacity linear, 300ms transform ease-in-out;
    }
  }
`
