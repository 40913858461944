import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { SectionOffer } from "./home-offer.style"
import SectionTitle from "../../section-title"
import CustomizedAccordion from "../accordion/accordion"
import Container from "../../container"



export default function HomeOffer() {
    const [isReady, setIsReady] = useState(true)

    const data = useStaticQuery(graphql`
    query OfferMyQuery {
        allWpOffer(sort: {date: DESC}) {
          nodes {
            ACF_offer {
              list
              links {
                link {
                  title
                  target
                  url
                }
              }
            }
            title
            slug
            content
          }
        }
      }
    `)


    useEffect(() => {
        setIsReady(true)
    }, [])

  const elements = data.allWpOffer.nodes

  return (
    <SectionOffer>
      {isReady && (
        <Container>
            <SectionTitle
              text="co_"
              textBold="napędzamy"
              textColor="#000"
            />
            <CustomizedAccordion data={elements} />
        </Container>
      )}
    </SectionOffer>
  )
}
