import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"

import Layout from "../../components/layout.js"
import { SEO } from "../../components/seo"
import HomeBanner from "../../components/homepage/banner/home-banner.js"
import CaseStudies from "../../components/homepage/case-studies/case-studies.js"
import HomeOffer from "../../components/homepage/offer/home-offer.js"
import HomeClients from "../../components/homepage/clients/home-clients.js"
import HomeAbout from "../../components/homepage/about/home-about.js"
import Realizations from "../../components/homepage/realizations/home-realizations.js"



export default function Home(PageProps) {
  const PageData = PageProps.pageContext;

  return (
    <Layout>
      <HomeBanner data={PageData.AcfHome}/>
      <CaseStudies data={PageData.AcfHome}/>
      <HomeOffer data={PageData.AcfHome}/>
      <HomeClients data={PageData.AcfHome}/>
      <HomeAbout data={PageData.AcfHome}/>
      <Realizations/>
    </Layout>
  );
}

export const Head = PageProps => {
   return (
    <SEO title={PageProps.pageContext.seo.title} description={PageProps.pageContext.seo.opengraphDescription} image={PageProps.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={PageProps.pageContext.seo.opengraphUrl}  />
   )
}
