import styled from "styled-components"

export const AboutSection = styled.section`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 10%;
    padding: 3rem 0;

    @media ${props => props.theme.sizes.laptop} {
        padding: 6rem 0;
    }


    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 12%;
        background: ${props => props.theme.colors.text};
        z-index: -1;

        @media ${props => props.theme.sizes.laptop} {
            height: 370px;
        }
    }


`
export const AboutText = styled.div`
    line-height: 1.6;
    font-size: 1.2rem;
    font-family: ${props => props.theme.fonts.secondary};
    font-weight: 200;
    padding-bottom: 1rem;

    @media ${props => props.theme.sizes.laptopL} {
        margin: 0 0 4rem 20%;
        width: clamp(45ch, 75ch, 75ch);
        font-size: 1.4rem;
        line-height: 2.72rem;
    }

    .black {
        font-weight: 500;
    }
`
